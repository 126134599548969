import { get, post, put, remove } from './api-service';
import { sprintf } from 'sprintf-js';

const routes = {
  base: 'users',
  withId: 'users/%s',
}

export const getUsers = async filters => {
  return await get(`${routes.base}?roles[]=ROLE_ADMIN&roles[]=ROLE_USER${filters}`);
}

export const getUser = async id => {
  return await get(sprintf(routes.withId, id))
};

export const createUser = async payload => {
  return  await post(routes.base, payload);
}

export const updateUser = async (id, payload) => {
  return await put(sprintf(routes.withId, id), payload);
}

export const removeUser = async id => {
  return await remove(sprintf(routes.withId, id));
}

export const getUserIncidences = async (id, filters) => {
  return await get(`${sprintf(routes.withId, id)}/incidences${filters}`);
}

export const getCustomers = async (filters = '') => {
  return await get(`${routes.base}?roles[]=ROLE_CUSTOMER${filters}`)
}

export const getTechnicians = async () => {
  return await getUsers('&order[name]=asc&itemsPerPage=1000&active=true');
};
