const LOADING = 'ourenapp/request/loading';

const initialState = {
  loading: false
};

export default (state = initialState, action) => {
  if (action.type === LOADING) {
    return {
      ...state,
      loading: action.value
    };
  }
  return state
}

export const setLoading = value => ({
  type: LOADING,
  value: value
});
