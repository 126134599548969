import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useForm from '../../hooks/use-form';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { alert, success } from '../../redux/ducks/notification';
import DeleteDialog from '../Dialog/delete-dialog';
import { getUser, updateUser } from '../../services/api/user-api-service';
import { getRoles } from '../../services/api/role-api-service';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  isLocked: {
    cursor: 'pointer'
  },
  form: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  select: {
    width: '100%',
  }
}));

export default function UserDetail({ match, history }) {
  const userId = match.params.id;
  const classes = useStyles();
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState(false);
  const loading = useSelector(state => state.request.loading);
  const dispatch = useDispatch();
  const [isLocked, setIsLocked] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [roles, setRoles] = useState([]);

  const stateSchema = {
    number: { value: '', error: '' },
    name: { value: '', error: '' },
    lastName: { value: '', error: '' },
    address: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' },
    password: { value: '', error: '' },
    role: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    number: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: ''
      }
    },
    name: {
      required: true,
      validator: {
        func: value => /^([A-Za-zñÑáéíóúÁÉÍÓÚ\s]{2,20})$/.test(value),
        error: t('user.form.validation.name'),
      },
    },
    lastName: {
      required: false,
      validator: {
        func: value => /^$|^([A-Za-zñÑáéíóúÁÉÍÓÚ\s]{2,20})$/.test(value),
        error: t('user.form.validation.last_name'),
      },
    },
    address: {
      required: false,
      validator: {
        func: value => /^$|^[\S\s]{2,200}$/.test(value),
        error: t('user.form.validation.address'),
      },
    },
    email: {
      required: true,
      validator: {
        func: value => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value),
        error: t('user.form.validation.email'),
      },
    },
    phone: {
      required: false,
      validator: {
        func: value => /^$|^.{9,50}$/.test(value),
        error: t('user.form.validation.phone'),
      },
    },
    password: {
      required: false,
      validator: {
        func: value => /^$|^.{6,50}$/.test(value),
        error: t('user.form.validation.password'),
      },
    },
    role: {
      required: false,
      validator: {
        func: value => value,
        error: t('user.form.validation.role'),
      },
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserStatus = async (status) => {
    try {
      await updateUser(userId, { active: status });
      dispatch(success(t('user.updated.success')));
      history.push('/dashboard/users');
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  };

  const onSubmitForm = async () => {
    let payload = {
      name: name,
      lastName: lastName ? lastName : null,
      address: address ? address : null,
      email: email,
      roles: [role]
    }
    if ('' !== password) {
      payload.password = password
    }
    if ('' !== phone) {
      payload.phone = phone;
    }

    try {
      await updateUser(userId, payload);
      dispatch(success(t('user.edit.success')));
      setIsLocked(true);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchUser = async () => {
    try {
      const response = await getUser(userId);
      const data = response.data;
      setIsActive(data.active);
      setStateSchema({
        number: { value: data.number, error: '' },
        name: { value: data.name, error: '' },
        lastName: { value: data.lastName, error: '' },
        address: { value: data.address, error: '' },
        email: { value: data.email, error: '' },
        phone: { value: data.phone ? data.phone : '', error: '' },
        password: { value: '', error: '' },
        role: { value: data.roles[0], error: '' }
      });
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchRoles = async () => {
    try {
      const response = await getRoles();
      setRoles(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  useEffect(() => {
    fetchUser();
    fetchRoles();
  }, []); // eslint-disable-line

  const {
    dirty,
    values,
    errors,
    setStateSchema,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { number, name, lastName, address, email, phone, password, role } = values;

  return (
    <div className={classes.root}>
      <DeleteDialog
        open={open}
        handleClose={handleClose}
        handleDelete={() => handleUserStatus(false)}
        title={t('dialog.user.title')}
        description={t('dialog.user.description')}
        buttonText={t('dialog.user.buttons.deactivate')}
      />
      <Container maxWidth={'md'}>
        <Paper>
          <Toolbar>
            <Grid container>
              <Grid item md={11} xs={11}>
                <Typography variant="h5">
                  {t('user.toolbar.edit') + ` #${number}`}
                </Typography>
              </Grid>
              <Grid className={classes.isLocked} onClick={() => setIsLocked(!isLocked)} item md={1} xs={1}>
                {isLocked ? <LockIcon fontSize={'default'} /> : <LockOpenIcon fontSize={'default'} />}
              </Grid>
            </Grid>
          </Toolbar>
          <div className={classes.paper}>
            <form
              className={classes.form}
              onSubmit={handleOnSubmit}
              noValidate
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label={t('user.form.fields.name')}
                    required
                    fullWidth
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={name}
                    error={errors.name.length > 0 && dirty.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="lastName"
                    label={t('user.form.fields.last_name')}
                    fullWidth
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={lastName || ''}
                    error={errors.lastName.length > 0 && dirty.lastName}
                    helperText={errors.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="address"
                    label={t('user.form.fields.address')}
                    fullWidth
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={address || ''}
                    error={errors.address.length > 0 && dirty.address}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    label={t('user.form.fields.email')}
                    required
                    fullWidth
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={email}
                    error={errors.email.length > 0 && dirty.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="phone"
                    label={t('user.form.fields.phone')}
                    fullWidth
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={phone || ''}
                    error={errors.phone.length > 0 && dirty.phone}
                    helperText={errors.phone}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="password"
                    label={t('user.form.fields.password')}
                    fullWidth
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={password || ''}
                    error={errors.password.length > 0 && dirty.password}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    <InputLabel id="role">{t('user.form.fields.roles')}</InputLabel>
                    <Select
                      fullWidth
                      id="role"
                      name="role"
                      disabled={isLocked}
                      value={role}
                      onChange={handleOnChange}
                    >
                      {roles.map(role => (
                        role.name !== 'ROLE_CUSTOMER'
                          ? <MenuItem key={role.id} value={role.name}>{t(`roles.${role.name}`)}</MenuItem>
                          : null
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {isLocked
                ? null
                :
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={disable || loading}
                  >
                    {t('user.form.fields.button.save')}
                  </Button>
                  {
                    isActive
                      ? <Button
                        style={{ marginLeft: '10px' }}
                        variant="contained"
                        className={classes.submit}
                        disabled={loading}
                        onClick={handleClickOpen}
                      >
                        {t('user.form.fields.button.deactivate')}
                      </Button>
                      : <Button
                        style={{ marginLeft: '10px' }}
                        variant="contained"
                        className={classes.submit}
                        disabled={loading}
                        onClick={() => handleUserStatus(true)}
                      >
                        {t('user.form.fields.button.restore')}
                      </Button>
                  }
                </Grid>
              }
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
}
