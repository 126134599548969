import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { alert, success, warning } from '../../redux/ducks/notification';
import useForm from '../../hooks/use-form';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createUser, getCustomers, getUsers } from '../../services/api/user-api-service';
import { createIncidence } from '../../services/api/incidence-api-service';
import { getStatuses } from '../../services/api/statuses-api-service';
import { incidence } from '../../constants/incidence';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  form: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  select: {
    width: '100%',
  },
  datePicker: {
    marginTop: '-16px'
  },
  customerData: {
    marginTop: 5,
    marginBottom: 10,
  },
  print: {
    marginTop: '18px'
  }
}));

const AssistanceCreate = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userId = useSelector(state => state.auth.id);

  const loading = useSelector(state => state.request.loading);
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [customer, setCustomer] = useState({});
  const [showCustomerForm, setShowCustomerForm] = useState(false);

  const stateSchema = {
    name: { value: '', error: '' },
    phone: { value: '', error: '' },
    technician: { value: '', error: '' },
    tasks: { value: '', error: '' },
    notes: { value: '', error: '' },
    price: { value: '', error: '' },
    status: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    name: {
      required: showCustomerForm,
      validator: {
        func: value => /^$|^([A-Za-zñÑáéíóúÁÉÍÓÚ\s]{2,20})$/.test(value),
        error: t('customer.form.validation.name'),
      },
    },
    phone: {
      required: showCustomerForm,
      validator: {
        func: value => /^$|^.{9,50}$/.test(value),
        error: t('customer.form.validation.phone'),
      },
    },
    technician: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('assistance.form.validation.technician')
      }
    },
    tasks: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: ''
      }
    },
    notes: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: ''
      }
    },
    price: {
      required: false,
      validator: {
        func: value => /^[-+]?\d*\.?\d*$/.test(value),
        error: ''
      }
    },
    status: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('assistance.form.validation.status')
      }
    },
  };

  const onSubmitForm = async () => {
    let customerId = null;
    if (showCustomerForm) {
      try {
        const response = await createUser({
          name: name,
          lastName: '',
          phone: phone,
          roles: ['ROLE_CUSTOMER'],
        });
        customerId = response.data.id;
      } catch (e) {
        if (409 === e.response.status) {
          dispatch(alert(t('errors.customer.phone_exists')));
          return;
        }
        dispatch(alert(t('errors.common.server')));
      }
    } else {
      if (Object.keys(customer).length === 0) {
        dispatch(warning(t('incidence.form.validation.customer')));
        return;
      }
    }
    await postAssistance(customerId ? customerId : customer.id);
  }

  const postAssistance = async (customerId) => {
    try {
      await createIncidence({
        owner: `${process.env.REACT_APP_API_PATH}/users/${userId}`,
        customer: `/api/v1/users/${customerId}`,
        technician: `/api/v1/users/${technician}`,
        type: incidence.external,
        tasks: tasks,
        notes: notes,
        price: price ? parseFloat(price) : null,
        status: `/api/v1/statuses/${status}`,
        date: date
      });
      dispatch(success(t('assistance.create.success')));
      history.push('/dashboard/assistance')
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const handleCustomerChange = (event, newValue) => {
    setCustomer(newValue);
  };

  const fetchCustomers = async () => {
    try {
      const response = await getCustomers('&order[name]=asc&itemsPerPage=10000');
      setCustomers(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchTechnicians = async () => {
    try {
      const response = await getUsers('&order[name]=asc&itemsPerPage=1000&active=true');
      setTechnicians(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchStatuses = async () => {
    try {
      const response = await getStatuses('?order[number]=asc');
      setStatuses(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')))
    }
  }

  useEffect(() => {
    fetchCustomers();
    fetchTechnicians();
    fetchStatuses();
  }, []); // eslint-disable-line

  const {
    dirty,
    values,
    errors,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { name, phone, technician, tasks, notes, price, status, date } = values;

  return (
    <div className={classes.root}>
      <Container maxWidth={'md'}>
        <Paper>
          <Toolbar>
            <Grid container>
              <Grid item md={11} xs={11}>
                <Typography variant="h5">
                  {t('assistance.toolbar.new')}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          <div className={classes.paper}>
            <form
              className={classes.form}
              onSubmit={handleOnSubmit}
              noValidate
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    onClick={() => setShowCustomerForm(!showCustomerForm)}
                  >
                    {
                      showCustomerForm
                        ? t('button.common.new_customer_hide')
                        : t('button.common.new_customer')
                    }
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ display: showCustomerForm ? '' : 'none' }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label={t('customer.form.fields.name')}
                    required={showCustomerForm}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={name}
                    error={errors.name.length > 0 && dirty.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="phone"
                    required={showCustomerForm}
                    label={t('customer.form.fields.phone')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={phone}
                    error={errors.phone.length > 0 && dirty.phone}
                    helperText={errors.phone}
                  />
                </Grid>
              </Grid>
              {
                showCustomerForm &&
                <Fragment>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant={'caption'}>{t('incidence.form.notes.customer')}</Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 5, marginBottom: 10 }} />
                </Fragment>
              }
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disabled={showCustomerForm}
                    options={customers}
                    getOptionLabel={customer => customer.name && customer.lastName ? `${customer.name} ${customer.lastName}` : customer.name || customer.lastName || ''}
                    value={customer}
                    autoComplete
                    onChange={handleCustomerChange}
                    includeInputInList
                    renderInput={params => <TextField fullWidth {...params}
                                                      label={t('assistance.form.fields.customer')} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl required className={classes.select}>
                    <InputLabel id="user">{t('incidence.form.fields.technician')}</InputLabel>
                    <Select
                      fullWidth
                      id="technician"
                      name="technician"
                      value={technician}
                      onChange={handleOnChange}
                    >
                      {technicians.map(technician => (
                        <MenuItem key={technician.id}
                                  value={technician.id}>{technician.name} {technician.lastName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="tasks"
                    label={t('assistance.form.fields.tasks')}
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={tasks}
                    error={errors.tasks.length > 0 && dirty.tasks}
                    helperText={errors.tasks}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="notes"
                    label={t('assistance.form.fields.notes')}
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={notes}
                    error={errors.notes.length > 0 && dirty.notes}
                    helperText={errors.notes}
                  />
                </Grid>
                <Grid className={classes.datePicker} item md={6} sm={12} xs={12}>
                  <KeyboardDateTimePicker
                    name="date"
                    fullWidth
                    format="DD/MM/YYYY - H:mm"
                    ampm={false}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    label={t('assistance.form.fields.date')}
                    value={date}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    <InputLabel id="status">{t('assistance.form.fields.status')}</InputLabel>
                    <Select
                      fullWidth
                      id="status"
                      name="status"
                      value={status}
                      onChange={handleOnChange}
                    >
                      {statuses.map(status => (
                        <MenuItem key={status.id} value={status.id}>{t(`status.${status.name}`)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    name="price"
                    type="number"
                    label={t('assistance.form.fields.price')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={price || 0}
                    error={errors.price.length > 0 && dirty.price}
                    helperText={errors.price}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={disable || loading}
                >
                  {t('assistance.form.fields.button.save')}
                </Button>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
};

export default AssistanceCreate;
