import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  link: {
    marginTop: 10,
    marginBottom: 10
  }
});

export default function LoadMore(props) {
  const { loadMore, buttonText } = props;
  const classes = useStyles();

  return (
    <Grid container justify="center" alignContent="center">
      <Grid item>
        <Button className={classes.link} component={Link} onClick={loadMore}>
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  )
};
