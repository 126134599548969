import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dashboard from '@material-ui/icons/Dashboard';
import People from '@material-ui/icons/PeopleAlt';
import Incidence from '@material-ui/icons/Error';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import CallIcon from '@material-ui/icons/Call';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/ducks/auth';
import { isAdmin } from '../../services/auth/auth-service';
import PersonIcon from '@material-ui/icons/Person';
import ProgressBar from '../../UI/ProgressBar/progress-bar';
import Typography from '@material-ui/core/Typography';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

function DashboardNavbar({ container, history, children }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const loading = useSelector(state => state.request.loading);
  const auth = useSelector(state => state.auth);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logout());
    history.push('/');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerToggleIfOpen = () => {
    if (mobileOpen) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <div>
            <List>
              <ListItem>
                <Dashboard />
                <Button component={Link} to="/dashboard/main" color="inherit"
                        onClick={handleDrawerToggleIfOpen}>{t('dashboard.drawer.dashboard')}</Button>
              </ListItem>
            </List>
            <Divider />
          </div>
      <List>
        {isAdmin(auth.role) &&
        <ListItem>
          <SupervisorAccountIcon />
          <Button component={Link} to="/dashboard/users" onClick={handleDrawerToggleIfOpen}
                  color="inherit">{t('dashboard.drawer.users')}</Button>
        </ListItem>
        }
        <ListItem>
          <People />
          <Button component={Link} to="/dashboard/customers" onClick={handleDrawerToggleIfOpen}
                  color="inherit">{t('dashboard.drawer.customers')}</Button>
        </ListItem>
        <ListItem>
          <Incidence />
          <Button component={Link} to="/dashboard/incidences" onClick={handleDrawerToggleIfOpen}
                  color="inherit">{t('dashboard.drawer.incidences')}</Button>
        </ListItem>
        <ListItem>
          <HomeWorkIcon />
          <Button component={Link} to="/dashboard/assistance" onClick={handleDrawerToggleIfOpen}
                  color="inherit">{t('dashboard.drawer.assistance')}</Button>
        </ListItem>
        <div>
          <ListItem>
            <ShoppingCartIcon />
            <Button component={Link} to="/dashboard/orders" onClick={handleDrawerToggleIfOpen}
                    color="inherit">{t('dashboard.drawer.orders')}</Button>
          </ListItem>
          <ListItem>
            <CallIcon />
            <Button component={Link} to="/dashboard/messages" onClick={handleDrawerToggleIfOpen}
                    color="inherit">{t('dashboard.drawer.messages')}</Button>
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <ListItem>
          <PersonIcon />
          <Button component={Link} to={`/dashboard/profile`} color="inherit"
                  onClick={handleDrawerToggleIfOpen}>{t('dashboard.drawer.profile')}</Button>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ExitToAppIcon />
          <Button onClick={logoutUser} color="inherit">{t('dashboard.drawer.logout')}</Button>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        {loading ? <ProgressBar /> : null}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <PersonIcon />
          <Typography variant="inherit" noWrap>
            {auth.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

export default withRouter(DashboardNavbar);
