import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    align: 'center'
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <Grid container spacing={1} direction="row" justify="space-between" alignContent="space-between">
        <Grid item md={12}>
          <Typography align="center" color="textSecondary">
            {'Copyright © '}
            {t('company_name')}
            {' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  )
};

export default Footer;
