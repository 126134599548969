import { get, post, put, remove } from './api-service';
import { sprintf } from 'sprintf-js';

const routes = {
  base: 'orders',
  withId: 'orders/%s',
}

export const getOrders = async (filters = '') => {
  return await get(`${routes.base}${filters}`);
}

export const getOrderById = async id => {
  return await get(sprintf(routes.withId, id))
}

export const createOrder = async payload => {
  return await post(routes.base, payload);
}

export const updateOrder = async (id, payload) => {
  return await put(sprintf(routes.withId, id), payload);
}

export const removeOrder = async id => {
  return await remove(sprintf(routes.withId, id));
}
