import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import TableToolbar from '../../components/Table/toolbar';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import EnhancedTableHead from '../../components/Table/enhanced-table-head';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Moment from 'react-moment';
import TableContainer from '@material-ui/core/TableContainer';
import { alert } from '../../redux/ducks/notification';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { getIncidencesByType } from '../../services/api/incidence-api-service';
import { incidence } from '../../constants/incidence';
import { getOrders } from '../../services/api/orders-api-service';
import { getMessages } from '../../services/api/messages-api-service';
import WeeklyIncidenceChart from '../../components/Charts/weekly-incidence-status';
import Divider from '@material-ui/core/Divider';
import AllTimeChart from '../../components/Charts/all-time-chart';
import MonthlyChart from '../../components/Charts/monthly-chart';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  link: {
    cursor: 'pointer'
  }
}));

const Main = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [incidences, setIncidences] = useState([]);
  const [assistance, setAssistance] = useState([]);
  const [orders, setOrders] = useState([]);
  const [messages, setMessages] = useState([]);

  const incidenceHeadCells = [
    { id: 'number', label: t('tables.incidence.thead.number'), sortable: false },
    { id: 'createdAt', label: t('tables.incidence.thead.created_at'), sortable: false },
    { id: 'customer.name', label: t('tables.incidence.thead.customer'), sortable: false },
    { id: 'items', label: t('tables.incidence.thead.items'), sortable: false },
    { id: 'status.name', label: t('tables.incidence.thead.status'), sortable: false },
  ];

  const assistanceHeadCells = [
    { id: 'number', label: t('tables.assistance.thead.number'), sortable: false },
    { id: 'createdAt', label: t('tables.assistance.thead.created_at'), sortable: false },
    { id: 'customer.name', label: t('tables.assistance.thead.customer'), sortable: false },
    { id: 'tasks', label: t('tables.assistance.thead.tasks'), sortable: false },
    { id: 'status.name', label: t('tables.assistance.thead.status'), sortable: false },
  ];

  const messagesHeadCells = [
    { id: 'number', label: t('tables.messages.thead.number'), sortable: false },
    { id: 'createdAt', label: t('tables.messages.thead.created_at'), sortable: false },
    { id: 'sender', label: t('tables.messages.thead.sender'), sortable: false },
    { id: 'receiver', label: t('tables.messages.thead.receiver'), sortable: false },
    { id: 'content', label: t('tables.messages.thead.content'), sortable: false },
  ];

  const ordersHeadCells = [
    { id: 'number', label: t('tables.orders.thead.number'), sortable: false },
    { id: 'createdAt', label: t('tables.orders.thead.created_at'), sortable: false },
    { id: 'customer.name', label: t('tables.orders.thead.customer'), sortable: false },
    { id: 'items', label: t('tables.orders.thead.items'), sortable: false },
    { id: 'status', label: t('tables.orders.thead.status'), sortable: false },
  ];

  const fetchIncidences = async () => {
    try {
      const response = await getIncidencesByType(incidence.internal, '&order[number]=asc&itemsPerPage=5');
      setIncidences(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchAssistance = async () => {
    try {
      const response = await getIncidencesByType(incidence.external, '&order[number]=asc&itemsPerPage=5');
      setAssistance(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchMessages = async () => {
    try {
      const response = await getMessages('?order[number]=asc&itemsPerPage=5');
      setMessages(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')))
    }
  }

  const fetchOrders = async () => {
    try {
      const response = await getOrders('?order[number]=asc&itemsPerPage=5')
      setOrders(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')))
    }
  }

  useEffect(() => {
    fetchIncidences();
    fetchAssistance();
    fetchOrders();
    fetchMessages();
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
        <Container maxWidth={'xl'}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6}>
                <WeeklyIncidenceChart />
            </Grid>
            <Grid item lg={8} md={6}>
              <AllTimeChart />
            </Grid>
            <Grid item lg={12} md={12}>
              <MonthlyChart />
            </Grid>
            <Grid item lg={12} md={12}>
              <Divider />
              <Toolbar>
                <Typography variant="h4">
                  {t('tables.main.description')}
                </Typography>
              </Toolbar>
              <Paper className={classes.paper}>
                <TableToolbar name={t('tables.incidence.name')} />
                <TableContainer className={classes.link} onClick={() => history.push('/dashboard/incidences')}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      order="number"
                      orderBy="asc"
                      headCells={incidenceHeadCells}
                    />
                    <TableBody>
                      {incidences.map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell>{row.number}</TableCell>
                            <TableCell>
                              <Moment format={'DD-MM-YYYY'}>
                                {row.createdAt}
                              </Moment>
                            </TableCell>
                            <TableCell>{row.customer.name}</TableCell>
                            <TableCell>{row.items}</TableCell>
                            <TableCell>{t(`status.${row.status.name}`)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12}>
              <Paper className={classes.paper}>
                <TableToolbar name={t('tables.assistance.name')} />
                <TableContainer className={classes.link} onClick={() => history.push('/dashboard/assistance')}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      order="number"
                      orderBy="asc"
                      headCells={assistanceHeadCells}
                    />
                    <TableBody>
                      {assistance.map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell>{row.number}</TableCell>
                            <TableCell>
                              <Moment format={'DD-MM-YYYY'}>
                                {row.createdAt}
                              </Moment>
                            </TableCell>
                            <TableCell>{row.customer.name}</TableCell>
                            <TableCell>{row.tasks}</TableCell>
                            <TableCell>{t(`status.${row.status.name}`)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12}>
              <Paper className={classes.paper}>
                <TableToolbar name={t('tables.messages.name')} />
                <TableContainer className={classes.link} onClick={() => history.push('/dashboard/messages')}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      order="createdAt"
                      orderBy="asc"
                      headCells={messagesHeadCells}
                    />
                    <TableBody>
                      {messages.map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell>{row.number}</TableCell>
                            <TableCell>
                              <Moment format={'DD-MM-YYYY'}>
                                {row.createdAt}
                              </Moment>
                            </TableCell>
                            <TableCell>{row.sender}</TableCell>
                            <TableCell>{row.receiver}</TableCell>
                            <TableCell>{row.content}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12}>
              <Paper className={classes.paper}>
                <TableToolbar name={t('tables.orders.name')} />
                <TableContainer className={classes.link} onClick={() => history.push('/dashboard/orders')}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      order="number"
                      orderBy="asc"
                      headCells={ordersHeadCells}
                    />
                    <TableBody>
                      {orders.map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell>{row.number}</TableCell>
                            <TableCell>
                              <Moment format={'DD-MM-YYYY'}>
                                {row.createdAt}
                              </Moment>
                            </TableCell>
                            <TableCell>{row.customer.name}</TableCell>
                            <TableCell>{row.items}</TableCell>
                            <TableCell>{t(`order_status.${row.orderStatus.name}`)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Container>
    </div>
  )
};

export default Main;
