import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart, Legend, PieSeries, Title, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
import Paper from '@material-ui/core/Paper';
import { getWeeklyIncidences } from '../../services/api/chart-api-service';
import { alert } from '../../redux/ducks/notification';
import { useDispatch } from 'react-redux';

const WeeklyIncidenceChart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    pending: 0,
    in_progress: 0,
    completed: 0
  });

  useEffect(() => {
    fetchData();
  }, []) // eslint-disable-line

  const fetchData = async () => {
    try {
      const response = await getWeeklyIncidences();
      response.data['hydra:member'].forEach(item => {
        switch (item.status.name) {
          case 'PENDING':
            setData(prevState => {
              return {
                ...prevState,
                pending: prevState.pending + 1
              }
            });
            break;
          case 'IN_PROGRESS':
            setData(prevState => {
              return {
                ...prevState,
                in_progress: prevState.in_progress + 1
              }
            });
            break;
          case 'COMPLETED':
            setData(prevState => {
              return {
                ...prevState,
                completed: prevState.completed + 1
              }
            });
            break;
          default:
            throw new Error('Invalid status name');
        }
      });
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  return (
    <Paper>
      <Chart
        data={[
          { incidences: t('status.PENDING'), amount: data.pending },
          { incidences: t('status.IN_PROGRESS'), amount: data.in_progress },
          { incidences: t('status.COMPLETED'), amount: data.completed },
        ]}
      >
        <PieSeries
          valueField="amount"
          argumentField="incidences"
          innerRadius={0.6}
        />
        <Title
          text={t('charts.weekly.title')}
        />
        <Animation />
        <Legend />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Paper>
  );
}

export default WeeklyIncidenceChart;
