import React from 'react'
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20
  }
}));

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <Typography className={classes.root} variant="h3" align="center">
        {t('errors.common.page_not_found')}
      </Typography>
    </Container>
  )
};

export default NotFound;
