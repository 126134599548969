import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Moment from 'react-moment';
import DeleteDialog from '../../components/Dialog/delete-dialog';
import { alert, success } from '../../redux/ducks/notification';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { getMessages } from '../../services/api/messages-api-service';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const EnhancedTableHead = props => {
  const { t } = useTranslation();
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: 'createdAt', numeric: false, disablePadding: true, label: t('tables.messages.thead.created_at') },
    { id: 'sender', numeric: false, disablePadding: true, label: t('tables.messages.thead.sender') },
    { id: 'receiver', numeric: false, disablePadding: false, label: t('tables.messages.thead.receiver') },
    { id: 'phone', numeric: false, disablePadding: false, label: t('tables.messages.thead.phone') },
    { id: 'content', numeric: false, disablePadding: false, label: t('tables.messages.thead.content') },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all messages' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const { t } = useTranslation();
  const classes = useToolbarStyles();
  const { numSelected, totalItems, handleClickOpen } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} {numSelected === 1 ? t('messages.toolbar.selected') : t('messages.toolbar.selected_plural')}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t('tables.messages.name') + ' (' + totalItems + ')'}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title={t('tooltip.message.delete')} placement={'left'} arrow={true}>
          <IconButton aria-label="delete">
            <DeleteIcon onClick={handleClickOpen} />
          </IconButton>
        </Tooltip>
      ) : null
      }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function Messages({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = React.useState(false);

  const fetchMessages = async () => {
    try {
      const response = await getMessages('?order[createdAt]=asc&itemsPerPage=1000');
      const data = response.data;
      setRows(data['hydra:member']);
      setTotalItems(data['hydra:totalItems']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  useEffect(() => {
    fetchMessages();
  }, []); // eslint-disable-line

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    let requests = [];
    selected.map(messageId => (
      requests.push(axios.delete(`${process.env.REACT_APP_API_PATH}/messages/${messageId}`))
    ));

    axios.all(requests)
      .then(() => {
        setOpen(false);
        dispatch(success(t('messages.delete.success')));
        setSelected([]);
        fetchMessages();
      })
      .catch(() => dispatch(alert(t('errors.common.server'))));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const content = (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={event => handleClick(event, row.id)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
              <TableCell component="th" id={labelId} scope="row" padding="none">
                <Moment format={'DD-MM-YYYY'}>
                  {row.createdAt}
                </Moment>
              </TableCell>
              <TableCell>{row.sender}</TableCell>
              <TableCell>{row.receiver}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.content}</TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );

  return (
    <div className={classes.root}>
      <AddCircleIcon onClick={() => history.push('/dashboard/messages/create')} fontSize={'large'} color={'primary'} />
      <Paper className={classes.paper}>
        <DeleteDialog
          open={open}
          handleClose={handleClose}
          handleDelete={handleDelete}
          title={t('dialog.messages.title')}
          description={t('dialog.messages.description')}
          buttonText={t('dialog.user.buttons.delete')}
        />
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalItems={totalItems}
          handleClickOpen={handleClickOpen}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {totalItems > 0 ? content : null}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}
