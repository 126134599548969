import React, { Fragment } from 'react'
import { Container } from '@material-ui/core'
import HomeNavbar from '../components/Navbars/home-navbar'
import Login from '../containers/auth/login';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../components/404/not-found';

const Auth = () => {
  return (
    <Fragment>
      <HomeNavbar />
      <Container maxWidth="lg">
        <Switch>
          <Route path="/" exact component={Login} />
          <Route component={NotFound} />
        </Switch>
      </Container>
    </Fragment>
  )
};

export default Auth;
