import { get } from './api-service';
import moment from 'moment';

const routes = {
  incidences: 'incidences',
  customers: 'users',
  monthly: 'charts/monthly'
};

export const getWeeklyIncidences = async () => {
  const firstDayOfWeek = moment().weekday(0).format('Y-MM-DD');
  const lastDayOfWeek = moment().weekday(6).format('Y-MM-DD');
  const query = `?createdAt[after]=${firstDayOfWeek}&createdAt[before]=${lastDayOfWeek}`;

  return await get(`${routes.incidences}${query}`);
}

export const getMonthlyChartData = async () => {
  return get(routes.monthly);
}
