import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Grid from '@material-ui/core/Grid';
import { Container, TableContainer } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useForm from '../../hooks/use-form';
import { alert, success } from '../../redux/ducks/notification';
import DeleteDialog from '../Dialog/delete-dialog';
import TableToolbar from '../Table/toolbar';
import Table from '@material-ui/core/Table';
import EnhancedTableHead from '../Table/enhanced-table-head';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Moment from 'react-moment';
import LoadMore from '../Table/load-more';
import { getUser, removeUser, updateUser, getUserIncidences } from '../../services/api/user-api-service';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  isLocked: {
    cursor: 'pointer'
  },
  form: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  select: {
    width: '100%',
  },
  link: {
    cursor: 'pointer'
  },
}));

export default function CustomerDetail({ match, history }) {
  const userId = match.params.id;
  const classes = useStyles();
  const { t } = useTranslation();

  const loading = useSelector(state => state.request.loading);
  const dispatch = useDispatch();
  const [isLocked, setIsLocked] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [incidences, setIncidences] = useState([]);
  const [next, setNext] = useState(undefined);

  const stateSchema = {
    number: { value: '', error: '' },
    name: { value: '', error: '' },
    lastName: { value: '', error: '' },
    address: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    number: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: ''
      }
    },
    name: {
      required: true,
      validator: {
        func: value => /^([A-Za-zñÑáéíóúÁÉÍÓÚ\s]{2,20})$/.test(value),
        error: t('customer.form.validation.name'),
      },
    },
    lastName: {
      required: false,
      validator: {
        func: value => /^$|^([A-Za-zñÑáéíóúÁÉÍÓÚ\s]{2,20})$/.test(value),
        error: t('customer.form.validation.last_name'),
      },
    },
    address: {
      required: false,
      validator: {
        func: value => /^$|^[\S\s]{2,200}$/.test(value),
        error: t('customer.form.validation.address'),
      },
    },
    email: {
      required: false,
      validator: {
        func: value => /^$|^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value),
        error: t('customer.form.validation.email'),
      },
    },
    phone: {
      required: true,
      validator: {
        func: value => /^$|^.{9,50}$/.test(value),
        error: t('customer.form.validation.phone'),
      },
    },
  };

  const headCells = [
    { id: 'createdAt', label: t('tables.incidence.thead.created_at'), sortable: false },
    { id: 'type', label: t('tables.incidence.thead.type'), sortable: false },
    { id: 'tasks', label: t('tables.incidence.thead.tasks'), sortable: false },
    { id: 'status.name', label: t('tables.incidence.thead.status'), sortable: false },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await removeUser(userId);
      setOpen(false);
      dispatch(success(t('customer.delete.success')));
      history.push('/dashboard/customers');
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  };

  const handleClick = id => {
    history.push(`/dashboard/incidences/${id}`);
  };

  const onSubmitForm = async () => {
    let payload = {
      name: name,
      lastName: lastName ? lastName : null,
      address: address ? address : null,
      email: email ? email : null,
    };

    if ('' !== phone) {
      payload.phone = phone;
    }

    try {
      await updateUser(userId, payload);
      dispatch(success(t('customer.edit.success')));
      setIsLocked(true);
    } catch (e) {
      if (409 === e.response.status) {
        if ('App\\Exception\\User\\UserPhoneAlreadyExistsException' === e.response.data.class) {
          dispatch(alert(t('errors.customer.phone_exists')));
        } else {
          dispatch(alert(t('errors.customer.email_registered')));
        }
        return;
      }
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchUser = async () => {
    try {
      const response = await getUser(userId);
      const data = response.data;
      setStateSchema({
        number: { value: data.number, error: '' },
        name: { value: data.name, error: '' },
        lastName: { value: data.lastName, error: '' },
        address: { value: data.address, error: '' },
        email: { value: data.email ? data.email : '', error: '' },
        phone: { value: data.phone, error: '' },
      });
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchUserIncidences = async (filters, loadMore = false) => {
    if (!filters) {
      filters = '?order[createdAt]=asc';
    }

    try {
      const response = await getUserIncidences(userId, filters);
      const data = response.data;
      setIncidences(loadMore ? incidences.concat(data['hydra:member']) : data['hydra:member']);
      if (data['hydra:view'] && undefined !== data['hydra:view']['hydra:next']) {
        setNext(data['hydra:view']['hydra:next'].split('incidences')[1]);
      } else {
        setNext(undefined);
      }
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  useEffect(() => {
    fetchUser();
    fetchUserIncidences();
  }, []); // eslint-disable-line

  const {
    dirty,
    values,
    errors,
    setStateSchema,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { number, name, lastName, address, email, phone } = values;

  return (
    <Fragment>
      <div className={classes.root}>
        <DeleteDialog
          open={open}
          handleClose={handleClose}
          handleDelete={handleDelete}
          title={t('dialog.customer.title')}
          description={t('dialog.customer.description')}
          buttonText={t('dialog.user.buttons.delete')}
        />
        <Container maxWidth={'md'}>
          <Paper>
            <Toolbar>
              <Grid container>
                <Grid item md={11} xs={11}>
                  <Typography variant="h5">
                    {t('customer.toolbar.edit') + ` #${number}`}
                  </Typography>
                </Grid>
                <Grid className={classes.isLocked} onClick={() => setIsLocked(!isLocked)} item md={1} xs={1}>
                  {isLocked ? <LockIcon fontSize={'default'} /> : <LockOpenIcon fontSize={'default'} />}
                </Grid>
              </Grid>
            </Toolbar>
            <div className={classes.paper}>
              <form
                className={classes.form}
                onSubmit={handleOnSubmit}
                noValidate
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="name"
                      label={t('user.form.fields.name')}
                      required
                      fullWidth
                      disabled={isLocked}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleOnChange}
                      value={name || ''}
                      error={errors.name.length > 0 && dirty.name}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="lastName"
                      label={t('user.form.fields.last_name')}
                      fullWidth
                      disabled={isLocked}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleOnChange}
                      value={lastName || ''}
                      error={errors.lastName.length > 0 && dirty.lastName}
                      helperText={errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="address"
                      label={t('user.form.fields.address')}
                      fullWidth
                      disabled={isLocked}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleOnChange}
                      value={address || ''}
                      error={errors.address.length > 0 && dirty.address}
                      helperText={errors.address}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      label={t('user.form.fields.email')}
                      fullWidth
                      disabled={isLocked}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleOnChange}
                      value={email}
                      error={errors.email.length > 0 && dirty.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="phone"
                      label={t('user.form.fields.phone')}
                      fullWidth
                      required
                      disabled={isLocked}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleOnChange}
                      value={phone || ''}
                      error={errors.phone.length > 0 && dirty.phone}
                      helperText={errors.phone}
                    />
                  </Grid>
                </Grid>
                {isLocked
                  ? null
                  :
                  <Grid item xs={12} sm={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={disable || loading}
                    >
                      {t('user.form.fields.button.save')}
                    </Button>
                    <Button
                      style={{ marginLeft: '10px' }}
                      variant="contained"
                      className={classes.submit}
                      disabled={loading}
                      onClick={handleClickOpen}
                    >
                      {t('user.form.fields.button.delete')}
                    </Button>
                  </Grid>
                }
              </form>
            </div>
          </Paper>
        </Container>
      </div>
      {
        incidences.length > 0
          ? <div style={{ marginTop: 30 }} className={classes.root}>
            <Container maxWidth={'md'}>
              <Paper className={classes.paper}>
                <TableToolbar name={t('tables.incidence.name_history')} />
                <TableContainer>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      order="desc"
                      orderBy="createdAt"
                      headCells={headCells}
                    />
                    <TableBody>
                      {incidences.map((row, index) => {
                        return (
                          <TableRow className={classes.link} hover key={row.id} onClick={() => handleClick(row.id)}>
                            <TableCell>
                              <Moment format={'DD-MM-YYYY'}>
                                {row.createdAt}
                              </Moment>
                            </TableCell>
                            <TableCell>{t(`incidence_types.${row.type}`)}</TableCell>
                            <TableCell>{row.items}</TableCell>
                            <TableCell>{t(`status.${row.status.name}`)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {next &&
                <LoadMore buttonText={t('tables.common.load_more')} loadMore={() => fetchUserIncidences(next, true)} />
                }
              </Paper>
            </Container>
          </div>
          : null
      }
    </Fragment>
  );
}
