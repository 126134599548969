const SAVE_TOKEN = 'ourenapp/auth/saveToken';
const LOGOUT = 'ourenapp/auth/logout';

const initialState = {
  token: null,
  id: null,
  name: null,
  username: null,
  role: null,
  active: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TOKEN:
      return {
        ...state,
        token: action.token,
        id: action.payload.id,
        name: action.payload.name,
        username: action.payload.username,
        role: action.payload.roles[0],
        active: action.payload.active
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        id: null,
        name: null,
        username: null,
        role: null,
        active: false
      };
    default:
      return state
  }
}

export const saveToken = (token, payload) => ({
  type: SAVE_TOKEN,
  token: token,
  payload: payload
});

export const logout = () => ({
  type: LOGOUT
});
