import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { alert, success } from '../../redux/ducks/notification';
import useForm from '../../hooks/use-form';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { createMessage } from '../../services/api/messages-api-service';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  form: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const MessageCreate = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const userId = useSelector(state => state.auth.id);
  const loading = useSelector(state => state.request.loading);
  const dispatch = useDispatch();

  const stateSchema = {
    sender: { value: '', error: '' },
    receiver: { value: '', error: '' },
    content: { value: '', error: '' },
    phone: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    sender: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('messages.form.validation.sender')
      }
    },
    receiver: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('messages.form.validation.receiver')
      }
    },
    content: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('messages.form.validation.content')
      }
    },
    phone: {
      required: false,
      validator: {
        func: value => /^$|^.{9,50}$/.test(value),
        error: t('messages.form.validation.phone')
      }
    },
  };

  const onSubmitForm = async () => {
    try {
      await createMessage({
        owner: `${process.env.REACT_APP_API_PATH}/users/${userId}`,
        sender: sender,
        receiver: receiver,
        content: content,
        phone: phone ? phone : null
      });
      dispatch(success(t('messages.create.success')));
      history.push('/dashboard/messages')
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const {
    dirty,
    values,
    errors,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { sender, receiver, content, phone } = values;

  return (
    <div className={classes.root}>
      <Container maxWidth={'md'}>
        <Paper>
          <Toolbar>
            <Grid container>
              <Grid item md={11} xs={11}>
                <Typography variant="h5">
                  {t('messages.toolbar.new')}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          <div className={classes.paper}>
            <form
              className={classes.form}
              onSubmit={handleOnSubmit}
              noValidate
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="sender"
                    required
                    label={t('messages.form.fields.sender')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={sender}
                    error={errors.sender.length > 0 && dirty.sender}
                    helperText={errors.sender}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="receiver"
                    required
                    label={t('messages.form.fields.receiver')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={receiver}
                    error={errors.receiver.length > 0 && dirty.receiver}
                    helperText={errors.receiver}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="phone"
                    label={t('messages.form.fields.phone')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={phone}
                    error={errors.phone.length > 0 && dirty.phone}
                    helperText={errors.phone}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="content"
                    required
                    label={t('messages.form.fields.content')}
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={content}
                    error={errors.content.length > 0 && dirty.content}
                    helperText={errors.content}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={disable || loading}
                >
                  {t('messages.form.fields.button.save')}
                </Button>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
};

export default MessageCreate;
