import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { alert, success } from '../../redux/ducks/notification';
import useForm from '../../hooks/use-form';
import DeleteDialog from '../Dialog/delete-dialog';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { getUsers } from '../../services/api/user-api-service';
import { getStatuses } from '../../services/api/statuses-api-service';
import {
  downloadAssistanceWorksheet, getIncidenceById,
  removeIncidence,
  updateIncidence
} from '../../services/api/incidence-api-service';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  isLocked: {
    cursor: 'pointer'
  },
  form: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  select: {
    width: '100%',
  },
  datePicker: {
    marginTop: '-16px'
  },
  customerData: {
    marginTop: 5,
    marginBottom: 10,
  },
  print: {
    marginTop: '18px'
  }
}));

const AssistanceDetail = ({ match, history }) => {
  const assistanceId = match.params.id;
  const classes = useStyles();
  const { t } = useTranslation();

  const loading = useSelector(state => state.request.loading);
  const dispatch = useDispatch();
  const [isLocked, setIsLocked] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [owner, setOwner] = useState('');
  const [technicians, setTechnicians] = useState([]);
  const [customerData, setCustomerData] = useState({
    name: '',
    phone: ''
  });
  const [statuses, setStatuses] = useState([]);

  const stateSchema = {
    technician: { value: '', error: '' },
    number: { value: '', error: '' },
    tasks: { value: '', error: '' },
    notes: { value: '', error: '' },
    price: { value: 0, error: '' },
    status: { value: '', error: '' },
    statusName: { value: '', error: '' },
    date: { value: moment(), error: '' },
  };

  const stateValidatorSchema = {
    technician: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('assistance.form.validation.technician')
      }
    },
    number: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: ''
      }
    },
    tasks: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: ''
      }
    },
    notes: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: ''
      }
    },
    price: {
      required: false,
      validator: {
        func: value => /^[-+]?\d*\.?\d*$/.test(value),
        error: ''
      }
    },
    status: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('assistance.form.validation.status')
      }
    },
    statusName: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: t('assistance.form.validation.status')
      }
    },
    date: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('assistance.form.validation.date')
      }
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await removeIncidence(assistanceId);
      dispatch(success(t('assistance.delete.success')));
      history.push('/dashboard/assistance');
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  };

  const handleDownloadAssistanceWorksheet = async () => {
    try {
      const response = await downloadAssistanceWorksheet(assistanceId);
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type });
      saveAs(blob, `Assistance_${assistanceId}.pdf`);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  };

  const onSubmitForm = async () => {
    try {
      await updateIncidence(assistanceId, {
        technician: `/api/v1/users/${technician}`,
        tasks: tasks,
        notes: notes,
        price: parseFloat(price),
        status: `/api/v1/statuses/${status}`,
        date: date
      });
      dispatch(success(t('assistance.edit.success')));
      setIsLocked(true);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchAssistance = async () => {
    try {
      const response = await getIncidenceById(assistanceId);
      const data = response.data;
      setStateSchema({
        technician: { value: data.technician.id, error: '' },
        number: { value: data.number, error: '' },
        tasks: { value: data.tasks, error: '' },
        notes: { value: data.notes, error: '' },
        price: { value: data.price, error: '' },
        status: { value: data.status.id, error: '' },
        statusName: { value: data.status.name, error: '' },
        date: { value: moment(data.date), error: '' },
      });
      setCustomerData({
        ...customerData,
        name: data.customer.name,
        phone: data.customer.phone,
      });
      setOwner(data.owner.name);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchTechnicians = async () => {
    try {
      const response = await getUsers('&order[name]=asc&itemsPerPage=1000&active=true');
      setTechnicians(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchStatuses = async () => {
    try {
      const response = await getStatuses('?order[number]=asc');
      setStatuses(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')))
    }
  }

  useEffect(() => {
    fetchAssistance();
    fetchTechnicians();
    fetchStatuses();
  }, []); // eslint-disable-line

  const {
    dirty,
    values,
    errors,
    setStateSchema,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { technician, number, tasks, notes, price, status, date } = values;

  return (
    <div className={classes.root}>
      <DeleteDialog
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title={t('dialog.assistance.title')}
        description={t('dialog.assistance.description')}
        buttonText={t('dialog.user.buttons.delete')}
      />
      <Container maxWidth={'md'}>
        <Paper>
          <Toolbar>
            <Grid container>
              <Grid item md={11} xs={11}>
                <Typography variant="h5">
                  {t('assistance.toolbar.edit') + ` #${number}`}
                </Typography>
              </Grid>
              <Grid className={classes.isLocked} onClick={() => setIsLocked(!isLocked)} item md={1} xs={1}>
                {isLocked ? <LockIcon fontSize={'default'} /> : <LockOpenIcon fontSize={'default'} />}
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          <div className={classes.paper}>
            <Container className={classes.customerData} maxWidth={'md'}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography>{t('incidence.form.fields.owner')}: <strong>{owner}</strong></Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography>{t('assistance.form.fields.customer')}: <strong>{`${customerData.name || ''} ${customerData.lastName || ''}`}</strong></Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography>{t('assistance.form.fields.phone')}: <strong>{customerData.phone}</strong></Typography>
                </Grid>
              </Grid>
            </Container>
            <form
              className={classes.form}
              onSubmit={handleOnSubmit}
              noValidate
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControl required className={classes.select}>
                    <InputLabel id="user">{t('incidence.form.fields.technician')}</InputLabel>
                    <Select
                      fullWidth
                      id="technician"
                      name="technician"
                      disabled={isLocked}
                      value={technician}
                      onChange={handleOnChange}
                    >
                      {technicians.map(technician => (
                        <MenuItem key={technician.id}
                                  value={technician.id}>{technician.name} {technician.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="tasks"
                    label={t('assistance.form.fields.tasks')}
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={tasks}
                    error={errors.tasks.length > 0 && dirty.tasks}
                    helperText={errors.tasks}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="notes"
                    label={t('assistance.form.fields.notes')}
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={notes}
                    error={errors.notes.length > 0 && dirty.notes}
                    helperText={errors.notes}
                  />
                </Grid>
                <Grid className={classes.datePicker} item md={6} sm={12} xs={12}>
                  <KeyboardDateTimePicker
                    name="date"
                    fullWidth
                    format="DD/MM/YYYY - H:mm"
                    ampm={false}
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    label={t('assistance.form.fields.date')}
                    value={date}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    <InputLabel id="status">{t('assistance.form.fields.status')}</InputLabel>
                    <Select
                      fullWidth
                      id="status"
                      name="status"
                      disabled={isLocked}
                      value={status}
                      onChange={handleOnChange}
                    >
                      {statuses.map(status => (
                        <MenuItem key={status.id} value={status.id}>{t(`status.${status.name}`)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    name="price"
                    type="number"
                    label={t('assistance.form.fields.price')}
                    fullWidth
                    disabled={isLocked}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={price || 0}
                    error={errors.price.length > 0 && dirty.price}
                    helperText={errors.price}
                  />
                </Grid>
                <Grid className={classes.print} item xs={6} sm={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size={'small'}
                    onClick={handleDownloadAssistanceWorksheet}
                  >
                    {t('assistance.form.fields.button.print')}
                  </Button>
                </Grid>
              </Grid>
              {isLocked
                ? null
                :
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={disable || loading}
                  >
                    {t('assistance.form.fields.button.save')}
                  </Button>
                  <Button
                    style={{ marginLeft: '10px' }}
                    variant="contained"
                    className={classes.submit}
                    disabled={loading}
                    onClick={handleClickOpen}
                  >
                    {t('assistance.form.fields.button.delete')}
                  </Button>
                </Grid>
              }
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
};

export default AssistanceDetail;
