import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import './index.css'
import App from './App'
import './i18n';
import throttle from 'lodash/throttle';
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import authReducer from './redux/ducks/auth'
import requestReducer from './redux/ducks/request'
import notificationReducer from './redux/ducks/notification'
import { saveState, loadState } from './localStorage'
import history from './services/router/history';

const rootReducer = combineReducers({
  auth: authReducer,
  request: requestReducer,
  notification: notificationReducer,
});

const persistedState = loadState();

const store = createStore(rootReducer, persistedState);

store.subscribe(throttle(() => {
  saveState({
    auth: store.getState().auth
  })
}, 1000));

const app = (
  <Provider store={store}>
    <Suspense fallback={<div>...</div>}>
      <Router history={history}>
        <App />
      </Router>
    </Suspense>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
