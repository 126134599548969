import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { alert, success, warning } from '../../redux/ducks/notification';
import useForm from '../../hooks/use-form';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createUser, getCustomers } from '../../services/api/user-api-service';
import { createOrder } from '../../services/api/orders-api-service';
import { getOrderStatuses } from '../../services/api/order-status-api-service';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  isLocked: {
    cursor: 'pointer'
  },
  form: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  select: {
    width: '100%',
  },
  datePicker: {
    marginTop: '-16px'
  },
  customerData: {
    marginTop: 5,
    marginBottom: 10,
  },
  print: {
    marginTop: '18px'
  }
}));

const OrderCreate = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const userId = useSelector(state => state.auth.id);
  const loading = useSelector(state => state.request.loading);
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [customer, setCustomer] = useState({});
  const [showCustomerForm, setShowCustomerForm] = useState(false);

  const stateSchema = {
    name: { value: '', error: '' },
    phone: { value: '', error: '' },
    items: { value: '', error: '' },
    notes: { value: '', error: '' },
    orderStatus: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    name: {
      required: showCustomerForm,
      validator: {
        func: value => /^$|^([A-Za-zñÑáéíóúÁÉÍÓÚ\s]{2,20})$/.test(value),
        error: t('customer.form.validation.name'),
      },
    },
    phone: {
      required: showCustomerForm,
      validator: {
        func: value => /^$|^.{9,50}$/.test(value),
        error: t('customer.form.validation.phone'),
      },
    },
    items: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('orders.form.validation.items')
      }
    },
    notes: {
      required: false,
      validator: {
        func: value => /^.*/.test(value),
        error: ''
      }
    },
    orderStatus: {
      required: true,
      validator: {
        func: value => /^.*/.test(value),
        error: t('orders.form.validation.order_status')
      }
    },
  };

  const onSubmitForm = async () => {
    let customerId = null;
    if (showCustomerForm) {
      try {
        const response = await createUser({
          name: name,
          lastName: '',
          phone: phone,
          roles: ['ROLE_CUSTOMER'],
        });
        customerId = response.data.id;
      } catch (e) {
        if (409 === e.response.status) {
          dispatch(alert(t('errors.customer.phone_exists')));
          return;
        }
        dispatch(alert(t('errors.common.server')));
      }
    } else {
      if (Object.keys(customer).length === 0) {
        dispatch(warning(t('incidence.form.validation.customer')));
        return;
      }
    }
    await postOrder(customerId ? customerId : customer.id);
  }

  const postOrder = async customerId => {
    try {
      await createOrder({
        owner: `${process.env.REACT_APP_API_PATH}/users/${userId}`,
        customer: `/api/v1/users/${customerId}`,
        items: items,
        notes: notes,
        orderStatus: `/api/v1/order_statuses/${orderStatus}`
      });
      dispatch(success(t('orders.create.success')));
      history.push('/dashboard/orders');
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const handleCustomerChange = (event, newValue) => {
    setCustomer(newValue);
  };

  const fetchCustomers = async () => {
    try {
      const response = await getCustomers('&order[name]=asc&itemsPerPage=10000');
      setCustomers(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  const fetchOrderStatuses = async () => {
    try {
      const response = await getOrderStatuses();
      setOrderStatuses(response.data['hydra:member']);
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  useEffect(() => {
    fetchCustomers();
    fetchOrderStatuses();
  }, []); // eslint-disable-line

  const {
    dirty,
    values,
    errors,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { name, phone, items, notes, orderStatus } = values;

  return (
    <div className={classes.root}>
      <Container maxWidth={'md'}>
        <Paper>
          <Toolbar>
            <Grid container>
              <Grid item md={11} xs={11}>
                <Typography variant="h5">
                  {t('orders.toolbar.new')}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          <div className={classes.paper}>
            <form
              className={classes.form}
              onSubmit={handleOnSubmit}
              noValidate
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    onClick={() => setShowCustomerForm(!showCustomerForm)}
                  >
                    {
                      showCustomerForm
                        ? t('button.common.new_customer_hide')
                        : t('button.common.new_customer')
                    }
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ display: showCustomerForm ? '' : 'none' }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label={t('customer.form.fields.name')}
                    required={showCustomerForm}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={name}
                    error={errors.name.length > 0 && dirty.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="phone"
                    required={showCustomerForm}
                    label={t('customer.form.fields.phone')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={phone}
                    error={errors.phone.length > 0 && dirty.phone}
                    helperText={errors.phone}
                  />
                </Grid>
              </Grid>
              {
                showCustomerForm &&
                <Fragment>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant={'caption'}>{t('incidence.form.notes.customer')}</Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 5, marginBottom: 10 }} />
                </Fragment>
              }
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    disabled={showCustomerForm}
                    options={customers}
                    getOptionLabel={customer => customer.name && customer.lastName ? `${customer.name} ${customer.lastName}` : customer.name || customer.lastName || ''}
                    value={customer}
                    autoComplete
                    onChange={handleCustomerChange}
                    includeInputInList
                    renderInput={params => <TextField fullWidth {...params} label={t('orders.form.fields.customer')} />}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="items"
                    label={t('orders.form.fields.items')}
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={items || ''}
                    error={errors.items.length > 0 && dirty.items}
                    helperText={errors.items}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="notes"
                    label={t('orders.form.fields.notes')}
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOnChange}
                    value={notes || ''}
                    error={errors.notes.length > 0 && dirty.notes}
                    helperText={errors.notes}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl required className={classes.select}>
                    <InputLabel id="orderStatus">{t('orders.form.fields.order_status')}</InputLabel>
                    <Select
                      fullWidth
                      id="orderStatus"
                      name="orderStatus"
                      value={orderStatus || ''}
                      onChange={handleOnChange}
                    >
                      {orderStatuses.map(orderStatus => (
                        <MenuItem key={orderStatus.id}
                                  value={orderStatus.id}>{t(`order_status.${orderStatus.name}`)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={disable || loading}
                >
                  {t('orders.form.fields.button.save')}
                </Button>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
};

export default OrderCreate;
