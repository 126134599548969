import { get, post, put, remove } from './api-service';
import { sprintf } from 'sprintf-js';

const routes = {
  base: 'incidences',
  withId: 'incidences/%s',
}

export const getIncidencesByType = async (type, filters = '') => {
  return await get(`${routes.base}?type=${type}&status.name[]=PENDING&status.name[]=IN_PROGRESS&status.name[]=COMPLETED${filters}`);
}

export const getIncidenceById = async id => {
  return await get(sprintf(routes.withId, id));
}

export const createIncidence = async payload => {
  return await post(routes.base, payload);
}

export const updateIncidence = async (id, payload) => {
  return await put(sprintf(routes.withId, id), payload);
}

export const removeIncidence = async id => {
  return await remove(sprintf(routes.withId, id));
}

export const downloadIncidenceWorksheet = async id => {
  return await get(`${sprintf(routes.withId, id)}/incidence_worksheet`, {
    responseType: 'blob'
  });
}

export const downloadAssistanceWorksheet = async id => {
  return await get(`${sprintf(routes.withId, id)}/assistance_worksheet`, {
    responseType: 'blob'
  });
}
