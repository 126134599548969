import axios from 'axios';

const API_PATH = process.env.REACT_APP_API_PATH;

export const get = async (path, config = {}) => {
  return await axios.get(`${API_PATH}/${path}`, config);
}

export const post = async (path, payload) => {
  return await axios.post(`${API_PATH}/${path}`, payload);
}

export const put = async (path, payload) => {
  return await axios.put(`${API_PATH}/${path}`, payload);
}

export const remove = async (path) => {
  return await axios.delete(`${API_PATH}/${path}`);
}
