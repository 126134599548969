import { post } from '../api/api-service';
import decode from 'jwt-decode';

const routes = {
  login_check: 'users/login_check',
};
const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_USER = 'ROLE_USER';

export const login = async (username, password) => {
  return await post(routes.login_check, {username, password});
}

export const decodeToken = token => {
  try {
    return decode(token)
  } catch (e) {
    console.log('Invalid token')
  }
};

export const isAdmin = role => {
  return ROLE_ADMIN === role
};

export const isUser = role => {
  return ROLE_USER === role
};
