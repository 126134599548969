import React, { Fragment, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import SearchBar from '../../components/Filters/search-bar';
import Paper from '@material-ui/core/Paper';
import TableToolbar from '../../components/Table/toolbar';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import EnhancedTableHead from '../../components/Table/enhanced-table-head';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Moment from 'react-moment';
import LoadMore from '../../components/Table/load-more';
import { alert } from '../../redux/ducks/notification';
import { orderStatuses } from '../../constants/order-statuses'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Tooltip } from '@material-ui/core';
import { getOrders } from '../../services/api/orders-api-service';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  link: {
    cursor: 'pointer'
  },
  form: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const Orders = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [orderList, setOrderList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [next, setNext] = useState(undefined);
  const [selector, setSelector] = useState('');
  const [value, setValue] = useState('');
  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment());
  const [status, setStatus] = useState('');

  const [order, setOrder] = useState({
    column: 'number',
    direction: 'asc'
  });

  const headCells = [
    { id: 'number', label: t('tables.orders.thead.number'), sortable: true },
    { id: 'createdAt', label: t('tables.orders.thead.created_at'), sortable: true },
    { id: 'customer.name', label: t('tables.orders.thead.customer'), sortable: true },
    { id: 'items', label: t('tables.orders.thead.items'), sortable: false },
    { id: 'orderStatus.name', label: t('tables.orders.thead.status'), sortable: false },
  ];

  const fields = [
    { value: 'number', name: t('tables.orders.thead.number') },
    { value: 'customer.name', name: t('tables.orders.thead.customer') },
    { value: 'createdAt', name: t('tables.orders.thead.created_at') },
    { value: 'items', name: t('tables.orders.thead.items') },
    { value: 'orderStatus.name', name: t('tables.orders.thead.status') },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = order.column === property && order.direction === 'asc';
    setOrder(prevState => {
      return {
        ...prevState,
        column: property,
        direction: isAsc ? 'desc' : 'asc'
      }
    })
  };

  const fetchOrders = async (filters, loadMore = false) => {
    if (!filters) {
      filters = buildFilters();
    }

    try {
      const response = await getOrders(filters);
      const data = response.data;
      setOrderList(loadMore ? orderList.concat(data['hydra:member']) : data['hydra:member']);
      setTotalItems(data['hydra:totalItems']);
      if (data['hydra:view'] && undefined !== data['hydra:view']['hydra:next']) {
        setNext(data['hydra:view']['hydra:next']);
      } else {
        setNext(undefined);
      }
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  useEffect(() => {
    fetchOrders();
  }, [order]); // eslint-disable-line

  const handleClick = id => {
    history.push(`/dashboard/orders/${id}`);
  };

  const handleSelector = event => {
    setSelector(event.target.value);
  };

  const handleValue = event => {
    setValue(event.target.value);
  };

  const handleFrom = data => {
    setFrom(moment(data))
  };

  const handleTo = data => {
    setTo(moment(data))
  };

  const handleStatus = event => {
    setStatus(event.target.value);
  };

  const buildFilters = () => {
    let filters = `?&order[${order.column}]=${order.direction}`;

    switch (selector) {
      case 'number':
        filters += `&number=${value}`;
        break;
      case 'createdAt':
        filters += `&createdAt[after]=${moment(from).format('Y-MM-DD')}&createdAt[before]=${moment(to).format('Y-MM-DD')}`;
        break;
      case 'customer.name':
        filters += `&customer.name=${value}`;
        break;
      case 'items':
        filters += `&items=${value}`;
        break;
      default:
        filters += ''
    }

    if (status.length > 0) {
      filters += `&orderStatus.name=${status}`
    }

    return filters;
  };

  const handleSubmit = event => {
    event.preventDefault();
    let filters = buildFilters();
    fetchOrders(filters);
  };

  const content = (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar name={t('tables.orders.name') + ' (' + totalItems + ')'} />
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              order={order.direction}
              orderBy={order.column}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {orderList.map((row, index) => {
                return (
                  <TableRow className={classes.link} hover key={row.id} onClick={() => handleClick(row.id)}>
                    <TableCell>{row.number}</TableCell>
                    <TableCell>
                      <Moment format={'DD-MM-YYYY'}>
                        {row.createdAt}
                      </Moment>
                    </TableCell>
                    <TableCell>{`${row.customer.name || ''} ${row.customer.lastName || ''}`}</TableCell>
                    <TableCell>{row.items}</TableCell>
                    <TableCell>{t(`order_status.${row.orderStatus.name}`)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {next &&
        <LoadMore buttonText={t('tables.common.load_more')} loadMore={() => fetchOrders(next, true)} />
        }
      </Paper>
    </div>
  );

  return (
    <Fragment>
      <Tooltip title={t('tooltip.order.create')} placement={'right'} arrow={true}>
        <AddCircleIcon
          onClick={() => history.push('/dashboard/orders/create')}
          fontSize={'large'}
          color={'primary'}
        />
      </Tooltip>
      <SearchBar
        from={from}
        handleFrom={handleFrom}
        to={to}
        handleTo={handleTo}
        handleSubmit={handleSubmit}
        selector={selector}
        handleSelector={handleSelector}
        fields={fields}
        value={value}
        handleValue={handleValue}
        status={status}
        handleStatus={handleStatus}
        statuses={orderStatuses}
      />
      {totalItems > 0 ? content : null}
    </Fragment>
  )
};

export default Orders;
