import { useState, useEffect, useCallback } from 'react';
import { getPropValues, isObject, isRequired, VALUE, ERROR } from './utils';
import moment from 'moment';

function useForm(
  stateSchema = {},
  stateValidatorSchema = {},
  submitFormCallback
) {
  const [state, setStateSchema] = useState(stateSchema);

  const [values, setValues] = useState(getPropValues(state, VALUE));
  const [errors, setErrors] = useState(getPropValues(state, ERROR));
  const [dirty, setDirty] = useState(getPropValues(state, false));

  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setStateSchema(stateSchema);
    setDisable(true); // Disable button in initial render.
    setInitialErrorState();
  }, []); // eslint-disable-line

  useEffect(() => {
    const values = getPropValues(state, VALUE);
    setValues(values);
    setErrors(
      Object.keys(values).reduce((accu, curr) => {
        accu[curr] = validateField(curr, values[curr]);
        return accu;
      }, {})
    );
  }, [state]); // eslint-disable-line

  useEffect(() => {
    if (isDirty) {
      setDisable(validateErrorState());
    }
  }, [errors, isDirty]); // eslint-disable-line

  const setFieldValue = ({ name, value }) => {
    setValues(prevState => ({ ...prevState, [name]: value }));
    setDirty(prevState => ({ ...prevState, [name]: true }));
  };

  const setFieldError = ({ name, error }) =>
    setErrors(prevState => ({ ...prevState, [name]: error }));

  const validateField = useCallback(
    (name, value) => {
      const validator = stateValidatorSchema;
      if (!validator[name]) return;

      const field = validator[name];

      let error = '';
      error = isRequired(value, field.required);

      if (isObject(field['validator']) && error === '') {
        const validateFieldByCallback = field['validator'];

        if (!validateFieldByCallback['func'](value, values)) {
          error = validateFieldByCallback['error'];
        }
      }

      return error;
    },
    [stateValidatorSchema, values]
  );

  const setInitialErrorState = useCallback(() => {
    Object.keys(errors).map(name =>
      setFieldError({ name, error: validateField(name, values[name]) })
    );
  }, [errors, values, validateField]);

  const validateErrorState = useCallback(
    () => Object.values(errors).some(error => error),
    [errors]
  );

  const handleOnSubmit = useCallback(
    event => {
      event.preventDefault();

      if (!validateErrorState()) {
        submitFormCallback(values);
      }
    },
    [validateErrorState, submitFormCallback, values]
  );

  const handleOnChange = useCallback(
    (event, data = null) => {
      setIsDirty(true);

      let name = '';
      let value  = '';

      if (undefined !== event.target) {
        name = event.target.name;
        value = event.target.value;
      } else {
        name = 'date';
        value = moment(data, "DD/MM/YYYY - H:mm");
      }

      const error = validateField(name, value);

      setFieldValue({ name, value });
      setFieldError({ name, error });
    },
    [validateField]
  );

  return {
    dirty,
    values,
    errors,
    disable,
    setStateSchema,
    setFieldValue,
    setFieldError,
    handleOnChange,
    handleOnSubmit,
    validateErrorState,
  };
}

export default useForm;
