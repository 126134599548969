import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Legend,
  Title,
  Tooltip,
  ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, Stack } from '@devexpress/dx-react-chart';
import { withStyles } from '@material-ui/core/styles';
import { getMonthlyChartData } from '../../services/api/chart-api-service';
import { months } from '../../constants/months';
import { alert } from '../../redux/ducks/notification';

const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});
const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const legendLabelStyles = () => ({
  label: {
    whiteSpace: 'nowrap',
  },
});
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);

const MonthlyChart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([
    {
      month: 'Enero',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Febrero',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Marzo',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Abril',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Mayo',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Junio',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Julio',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Agosto',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Septiembre',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Octubre',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Noviembre',
      incidences: 0,
      assistance: 0,
      orders: 0
    }, {
      month: 'Diciembre',
      incidences: 0,
      assistance: 0,
      orders: 0
    }
  ]);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const fetchData = async () => {
    try {
      const response = await getMonthlyChartData();

      response.data[0].forEach(item => {
        setData([...data, data.push({
          month: t(`months.${months[item[0] - 1]}`),
          incidences: item[1],
          assistance: item[2],
          orders: item[3]
        })]);
      });
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  return (
    <Paper>
      <Chart
        data={data}
      >
        <ArgumentAxis />
        <ValueAxis />
        <BarSeries
          name={t('charts.monthly.legend.incidences')}
          valueField="incidences"
          argumentField="month"
          color="#ff7043"
        />
        <BarSeries
          name={t('charts.monthly.legend.assistance')}
          valueField="assistance"
          argumentField="month"
          color="#9ccc65"
        />
        <BarSeries
          name={t('charts.monthly.legend.orders')}
          valueField="orders"
          argumentField="month"
          color="#42a5f5"
        />
        <Animation />
        <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
        <Title text={t('charts.monthly.title')} />
        <Stack />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Paper>
  );
}

export default MonthlyChart;
