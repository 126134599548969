import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { createMuiTheme, CssBaseline, makeStyles, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from './redux/ducks/request'
import './App.css'
import AuthLayout from './layout/auth'
import DashboardLayout from './layout/dashboard'
import Footer from './components/Footers/footer'
import Notification from './UI/Notification/notification';
import moment from 'moment';
import { logout } from './redux/ducks/auth';
import history from './services/router/history';
import { warning } from './redux/ducks/notification';
import { useTranslation } from 'react-i18next';

const themeObject = {
  palette: {
    primary: {
      main: '#888888'
    },
    secondary: {
      // main: '#ef7f2d'
      main: '#888888'
    },
    type: 'light'
  }
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
});

const App = () => {
  moment.locale('es');
  const { t } = useTranslation();
  const classes = useStyles();
  const themeConfig = createMuiTheme(themeObject);
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification);

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.headers.Authorization = `Bearer ${token}`;

  axios.interceptors.request.use(config => {
    dispatch(setLoading(true));
    return config;
  }, error => {
    dispatch(setLoading(false));
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    dispatch(setLoading(false));
    return response;
  }, e => {
    dispatch(setLoading(false));
    if (401 === e.response.status && '/api/v1/users/login_check' !== e.config.url) {
      dispatch(logout());
      dispatch(warning(t('errors.common.token_expired')));
      history.push('/');
      return new Promise(() => {});
    }
    return Promise.reject(e);
  });

  return (
    <ThemeProvider theme={themeConfig}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <main className={classes.root} style={{ position: 'relative' }}>
          <Notification severity={notification.severity} message={notification.message} />
          <CssBaseline />
          <Switch>
            <Route path="/dashboard" render={props => <DashboardLayout {...props} />} />
            <Route path="/" render={props => <AuthLayout {...props} />} />
          </Switch>
          <Footer />
        </main>
      </MuiPickersUtilsProvider>

    </ThemeProvider>
  );
};

export default App;
