import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import TableToolbar from '../../components/Table/toolbar';
import LoadMore from '../../components/Table/load-more';
import EnhancedTableHead from '../../components/Table/enhanced-table-head';
import { useDispatch, useSelector } from 'react-redux';
import { isAdmin } from '../../services/auth/auth-service';
import SearchBar from '../../components/Filters/search-bar';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Tooltip } from '@material-ui/core';
import { getUsers } from '../../services/api/user-api-service';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  link: {
    cursor: 'pointer'
  },
  form: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Users = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if (null === auth.token) {
      history.push('/')
    } else if (!isAdmin(auth.role)) {
      history.push('/dashboard/main')
    }
  }, []); // eslint-disable-line

  const [users, setUsers] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [next, setNext] = useState(undefined);
  const [selector, setSelector] = useState('');
  const [value, setValue] = useState('');

  const [order, setOrder] = useState({
    column: 'name',
    direction: 'asc'
  });

  const headCells = [
    { id: 'number', label: t('tables.users.thead.number'), sortable: true },
    { id: 'name', label: t('tables.users.thead.name'), sortable: true },
    { id: 'email', label: t('tables.users.thead.email'), sortable: true },
    { id: 'roles', label: t('tables.users.thead.roles'), sortable: false },
  ];

  const fields = [
    { value: 'number', name: t('tables.users.thead.number') },
    { value: 'name', name: t('tables.users.thead.name') },
    { value: 'email', name: t('tables.users.thead.email') },
    { value: 'active', name: t('tables.users.thead.active') },
    { value: 'deactivated', name: t('tables.users.thead.deactivated') },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = order.column === property && order.direction === 'asc';
    setOrder(prevState => {
      return {
        ...prevState,
        column: property,
        direction: isAsc ? 'desc' : 'asc'
      }
    })
  };

  const buildFilters = () => {
    let filters = `&order[${order.column}]=${order.direction}`;

    switch (selector) {
      case 'number':
        filters += `&number=${value}`;
        break;
      case 'name':
        filters += `&name=${value}`;
        break;
      case 'email':
        filters += `&email=${value}`;
        break;
      case 'active':
        filters += '&active=true';
        break;
      case 'deactivated':
        filters += '&active=false';
        break;
      default:
        filters += '&active=true'
    }

    return filters;
  };

  const fetchUsers = async (filters = null, loadMore = false) => {
    if (null === filters) {
      filters = buildFilters();
    }

    try {
      const response = await getUsers(filters);
      const data = response.data;
      setUsers(loadMore ? users.concat(data['hydra:member']) : data['hydra:member']);
      setTotalItems(data['hydra:totalItems']);
      if (data['hydra:view'] && undefined !== data['hydra:view']['hydra:next']) {
        setNext(data['hydra:view']['hydra:next'].split('ROLE_USER')[1]);
      } else {
        setNext(undefined);
      }
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [order]); // eslint-disable-line

  const handleFiltersSubmit = event => {
    event.preventDefault();
    let filters = buildFilters();
    fetchUsers(filters);
  };

  const handleClick = id => {
    history.push(`/dashboard/users/${id}`);
  };

  const handleSelector = event => {
    setSelector(event.target.value);
  };

  const handleValue = event => {
    setValue(event.target.value);
  };

  const content = (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar name={t('tables.users.name') + ' (' + totalItems + ')'} />
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              order={order.direction}
              orderBy={order.column}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {users.map((row, index) => {
                return (
                  <TableRow className={classes.link} hover key={row.id} onClick={() => handleClick(row.id)}>
                    <TableCell>{row.number}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{t(`roles.${row.roles[0]}`)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {next &&
        <LoadMore buttonText={t('tables.common.load_more')} loadMore={() => fetchUsers(next, true)} />
        }
      </Paper>
    </div>
  );

  return (
    <Fragment>
      <Tooltip title={t('tooltip.user.create')} placement={'right'} arrow={true}>
        <AddCircleIcon
          onClick={() => history.push('/dashboard/users/create')}
          fontSize={'large'}
          color={'primary'}
        />
      </Tooltip>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <SearchBar
            selector={selector}
            value={value}
            handleSelector={handleSelector}
            handleSubmit={handleFiltersSubmit}
            fields={fields}
            handleValue={handleValue}
          />
        </Paper>
      </div>
      {totalItems > 0 ? content : null}
    </Fragment>
  );
};

export default Users;

