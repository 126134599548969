import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../../hooks/use-form';
import axios from 'axios';
import { alert, success, warning } from '../../redux/ducks/notification';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
  link: {
    color: 'inherit'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Profile = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.id);
  const loading = useSelector(state => state.request.loading);
  const [showPassword, setShowPassword] = useState(false);

  const stateSchema = {
    oldPassword: { value: '', error: '' },
    password: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    oldPassword: {
      required: true,
      validator: {
        func: value => /^.{6,20}$/.test(value),
        error: t('login.form.validation.password'),
      },
    },
    password: {
      required: true,
      validator: {
        func: value => /^.{6,20}$/.test(value),
        error: t('login.form.validation.password'),
      },
    },
  };

  function onSubmitForm() {
    axios.put(`${process.env.REACT_APP_API_PATH}/users/${userId}/change_password`, {
      old_password: oldPassword,
      new_password: password
    })
      .then(() => {
        dispatch(success(t('profile.success')));
        history.push('/dashboard/main');
      })
      .catch(error => {
        if (400 === error.response.status) {
          dispatch(warning(t('errors.profile')));
          return;
        }
        dispatch(alert(t('errors.common.server')));
      });
  }

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { oldPassword, password } = values;

  return (
    <Container className={classes.main} component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Toolbar>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Typography variant="h5">
                {t('profile.title')}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        <form
          id="profileForm"
          className={classes.form}
          onSubmit={handleOnSubmit}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="oldPassword"
            label={t('profile.form.fields.old_password')}
            value={oldPassword}
            onChange={handleOnChange}
            type="password"
            id="oldPassword"
            autoComplete="current-password"
            error={errors.oldPassword.length > 0 && dirty.oldPassword}
            helperText={errors.oldPassword}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('profile.form.fields.password')}
            value={password}
            onChange={handleOnChange}
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            error={errors.password.length > 0 && dirty.password}
            helperText={errors.password}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                value={showPassword}
                color="primary"
              />
            }
            label={t('profile.form.fields.show_password')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={disable || loading}
          >
            {t('profile.button')}
          </Button>
        </form>
      </div>
    </Container>
  )
};

export default Profile;
