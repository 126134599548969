import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveToken } from '../../redux/ducks/auth';
import { alert, warning } from '../../redux/ducks/notification';
import useForm from '../../hooks/use-form';
import { login, decodeToken, isAdmin } from '../../services/auth/auth-service';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  link: {
    color: 'inherit'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const role = useSelector(state => state.auth.role);
  const loading = useSelector(state => state.request.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAdmin(role)) {
      history.push('/dashboard/main');
    }
  }, []); // eslint-disable-line

  const stateSchema = {
    email: { value: '', error: '' },
    password: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    email: {
      required: true,
      validator: {
        func: value => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value),
        error: t('login.form.validation.email'),
      },
    },
    password: {
      required: true,
      validator: {
        func: value => /^.{6,20}$/.test(value),
        error: t('login.form.validation.password'),
      },
    },
  };

  const onSubmitForm = async () => {
    try {
      const response = await login(email, password);
      const token = response.data.token;
      const payload = decodeToken(token);
      dispatch(saveToken(token, payload));
      history.push('/dashboard/main')
    } catch (e) {
      if (undefined === e.response) {
        dispatch(alert(t('errors.common.no_connection')));
        return;
      }

      if (401 === e.response.status) {
        dispatch(warning(t('errors.login.invalid_credentials')));
        return;
      }

      if (409 === e.response.status) {
        dispatch(alert(t('errors.login.inactive_account')));
        return;
      }

      dispatch(alert(t('errors.common.server')));
    }
  }

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { email, password } = values;

  return (
    <div className={classes.paper}>
      <Container maxWidth="xs">
        <form
          className={classes.form}
          onSubmit={handleOnSubmit}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('login.form.email')}
            name="email"
            value={email}
            onChange={handleOnChange}
            autoComplete="email"
            error={errors.email.length > 0 && dirty.email}
            helperText={errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('login.form.password')}
            value={password}
            onChange={handleOnChange}
            type="password"
            id="password"
            autoComplete="current-password"
            error={errors.password.length > 0 && dirty.password}
            helperText={errors.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={disable || loading}
          >
            {t('login.form.button.login')}
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default Login;
