export const ALERT = 'ourenapp/notification/alert';
export const WARNING = 'ourenapp/notification/warning';
export const INFO = 'ourenapp/notification/info';
export const SUCCESS = 'ourenapp/notification/success';
export const CLEAR = 'ourenapp/notification/clear';

const initialState = {
  severity: '',
  message: '',
  isOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT:
      return {
        ...state,
        severity: 'error',
        message: action.message,
        isOpen: true
      };
    case WARNING:
      return {
        ...state,
        severity: 'warning',
        message: action.message,
        isOpen: true
      };
    case INFO:
      return {
        ...state,
        severity: 'info',
        message: action.message,
        isOpen: true
      };
    case SUCCESS:
      return {
        ...state,
        severity: 'success',
        message: action.message,
        isOpen: true
      };
    case CLEAR:
      return {
        ...state,
        isOpen: false
      };
    default:
      return state;
  }
}

export const alert = message => ({
  type: ALERT,
  message: message
});

export const warning = message => ({
  type: WARNING,
  message: message
});

export const info = message => ({
  type: INFO,
  message: message
});

export const success = message => ({
  type: SUCCESS,
  message: message
});

export const clear = () => ({
  type: CLEAR
});
