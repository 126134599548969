import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';

export default function TableToolbar({ name }) {
  return (
    <Toolbar>
      <Typography variant="h5">
        {name}
      </Typography>
    </Toolbar>
  );
}
