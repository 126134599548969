import React, { useEffect } from 'react'
import { Container } from '@material-ui/core'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DashboardNavbar from '../components/Navbars/dashboard-navbar'
import { useSelector } from 'react-redux';
import Main from '../containers/dashboard/main';
import Customers from '../containers/dashboard/customers';
import Incidences from '../containers/dashboard/incidences';
import Assistance from '../containers/dashboard/assistance';
import Messages from '../containers/dashboard/messages';
import Orders from '../containers/dashboard/orders';
import Users from '../containers/dashboard/users';
import UserDetail from '../components/Users/user-detail';
import UserCreate from '../components/Users/user-create';
import Profile from '../containers/dashboard/profile';
import CustomerCreate from '../components/Customers/customer-create';
import CustomerDetail from '../components/Customers/customer-detail';
import IncidenceDetail from '../components/Incidences/incidence-detail';
import IncidenceCreate from '../components/Incidences/incidence-create';
import AssistanceDetail from '../components/Assistance/assistance-detail';
import AssistanceCreate from '../components/Assistance/assistance-create';
import OrderDetail from '../components/Orders/order-detail';
import OrderCreate from '../components/Orders/order-create';
import MessageCreate from '../components/Messages/message-create';

const Dashboard = ({ history }) => {
  const auth = useSelector(state => state.auth);
  const { url } = useRouteMatch();

  useEffect(() => {
    if (null === auth.token) {
      history.push('/')
    }
  }, []); // eslint-disable-line

  return (
    <DashboardNavbar>
      <Container maxWidth="xl">
        <main>
          <Switch>
            <Route path={`${url}/profile`} component={Profile} />
            <Route path={`${url}/main`} component={Main} />
            <Route path={`${url}/users/create`} component={UserCreate} />
            <Route path={`${url}/users/:id`} component={UserDetail} />
            <Route path={`${url}/users`} component={Users} />
            <Route path={`${url}/customers/create`} component={CustomerCreate} />
            <Route path={`${url}/customers/:id`} component={CustomerDetail} />
            <Route path={`${url}/customers`} component={Customers} />
            <Route path={`${url}/incidences/create`} component={IncidenceCreate} />
            <Route path={`${url}/incidences/:id`} component={IncidenceDetail} />
            <Route path={`${url}/incidences`} component={Incidences} />
            <Route path={`${url}/assistance/create`} component={AssistanceCreate} />
            <Route path={`${url}/assistance/:id`} component={AssistanceDetail} />
            <Route path={`${url}/assistance`} component={Assistance} />
            <Route path={`${url}/messages/create`} component={MessageCreate} />
            <Route path={`${url}/messages`} component={Messages} />
            <Route path={`${url}/orders/create`} component={OrderCreate} />
            <Route path={`${url}/orders/:id`} component={OrderDetail} />
            <Route path={`${url}/orders`} component={Orders} />
          </Switch>
        </main>
      </Container>
    </DashboardNavbar>
  )
};

export default Dashboard;
