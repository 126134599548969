import { get, post } from './api-service';

const routes = {
  base: 'messages',
  withId: 'messages/%s',
}

export const getMessages = async filters => {
  return await get(`${routes.base}${filters}`);
}

export const createMessage = async payload => {
  return await post(routes.base, payload);
}
