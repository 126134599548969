import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import uuid from 'react-uuid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import history from '../../services/router/history';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  link: {
    cursor: 'pointer'
  },
  form: {
    margin: theme.spacing(1),
  },
  datePicker: {
    marginTop: '-16px'
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const SearchBar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    from, handleFrom, to, handleTo, handleSubmit, selector, handleSelector,
    fields, value, handleValue, status, statuses, handleStatus,
    location, locations, handleLocation
  } = props;
  const textFieldWidth = locations ? 4 : (statuses ? 4 : 8);
  const datePickersWidth = statuses ? 2 : 4;
  const statusWidth = locations ? 2 : 4;

  const refresh = () => {
    history.push('/');
    history.goBack();
  }

  const datePickers = (
    <Fragment>
      <Grid className={classes.datePicker} item md={datePickersWidth} sm={6} xs={6}>
        <KeyboardDatePicker
          name="from"
          fullWidth
          autoOk={true}
          format="DD/MM/YYYY"
          margin="normal"
          label={t('filters.from.value')}
          value={from}
          onChange={handleFrom}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <Grid className={classes.datePicker} item md={datePickersWidth} sm={6} xs={6}>
        <KeyboardDatePicker
          name="to"
          fullWidth
          autoOk={true}
          format="DD/MM/YYYY"
          margin="normal"
          label={t('filters.to.value')}
          value={to}
          onChange={handleTo}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </Fragment>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate
        >
          <Grid container spacing={1}>
            <Grid item md={2} sm={12} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel>{t('filters.select.field')}</InputLabel>
                <Select
                  name="field"
                  value={selector}
                  onChange={handleSelector}
                >
                  {fields.map(filter => (
                    <MenuItem key={uuid()} value={filter.value}>{filter.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {
              selector === 'createdAt' || selector === 'date'
                ? datePickers
                : <Grid item md={textFieldWidth} sm={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="value"
                      label={t('filters.text_field.value')}
                      fullWidth
                      disabled={(selector === 'active' || selector === 'deactivated')}
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      onChange={handleValue}
                    />
                  </FormControl>
                </Grid>
            }
            {
              statuses
                ? <Grid item md={statusWidth} sm={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="status_select">{t('filters.status.value')}</InputLabel>
                    <Select
                      labelId="status_select"
                      name="status"
                      value={status || ''}
                      onChange={handleStatus}
                    >
                      <MenuItem key={uuid()} value="">--</MenuItem>
                      {
                        statuses.length > 0
                          ? statuses.map(status => (
                            <MenuItem key={uuid()} value={status.value}>{t(`${status.name}`)}</MenuItem>
                          ))
                          : null
                      }
                    </Select>
                  </FormControl>
                </Grid>
                : null
            }
            {
              locations
                ? <Grid item md={2} sm={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="location_select">{t('filters.location.value')}</InputLabel>
                    <Select
                      labelId="location_select"
                      name="location"
                      value={location}
                      onChange={handleLocation}
                    >
                      <MenuItem key={uuid()} value="">--</MenuItem>
                      {locations.map(location => (
                        <MenuItem key={uuid()} value={location.value}>{t(`${location.name}`)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                : null
            }
            <Grid item md={1} sm={6} xs={6} style={{ marginTop: '11px' }}>
              <FormControl className={classes.formControl}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  <SearchIcon />
                </Button>
              </FormControl>
            </Grid>
            <Grid item md={1} sm={6} xs={6} style={{ marginTop: '11px' }}>
              <FormControl className={classes.formControl}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => refresh()}
                >
                  <ClearIcon />
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default SearchBar;
