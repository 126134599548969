import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Title,
  Tooltip,
  ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import { alert } from '../../redux/ducks/notification';
import { useDispatch } from 'react-redux';
import { getCustomers } from '../../services/api/user-api-service';
import { getIncidencesByType } from '../../services/api/incidence-api-service';
import { incidence } from '../../constants/incidence';
import { getOrders } from '../../services/api/orders-api-service';

const AllTimeChart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    customers: 0,
    incidences: 0,
    assistance: 0,
    orders: 0,
  });

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const fetchData = async () => {
    try {
      const customersRes = await getCustomers();
      setData(prevState => {
        return {
          ...prevState,
          customers: prevState.customers + customersRes.data['hydra:totalItems']
        }
      });
      const incidencesRes = await getIncidencesByType(incidence.internal, '&status.name[]=TERMINATED');
      setData(prevState => {
        return {
          ...prevState,
          incidences: prevState.incidences + incidencesRes.data['hydra:totalItems']
        }
      });
      const assistanceRes = await getIncidencesByType(incidence.external);
      setData(prevState => {
        return {
          ...prevState,
          assistance: prevState.assistance + assistanceRes.data['hydra:totalItems']
        }
      });
      const ordersRes = await getOrders();
      setData(prevState => {
        return {
          ...prevState,
          orders: prevState.orders + ordersRes.data['hydra:totalItems']
        }
      });
    } catch (e) {
      dispatch(alert(t('errors.common.server')));
    }
  }

  return (
    <Paper>
      <Chart
        data={[
          { category: t('charts.all_the_time.columns.customers'), value: data.customers },
          { category: t('charts.all_the_time.columns.incidences'), value: data.incidences },
          { category: t('charts.all_the_time.columns.assistance'), value: data.assistance },
          { category: t('charts.all_the_time.columns.orders'), value: data.orders },
        ]}
      >
        <ArgumentAxis />
        <ValueAxis />
        <BarSeries
          valueField="value"
          argumentField="category"
        />
        <Title text={t('charts.all_the_time.title')} />
        <Animation />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Paper>
  );
}

export default AllTimeChart;
