import React from 'react'

import {
  AppBar,
  Button,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Toolbar,
  useTheme
} from '@material-ui/core'

import { Link } from 'react-router-dom'

import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Menu'
import Fingerprint from '@material-ui/icons/Fingerprint'
import { useTranslation } from 'react-i18next';
import ProgressBar from '../../UI/ProgressBar/progress-bar';
import { useSelector } from 'react-redux';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  icons: {
    marginRight: '5px'
  }
}));

const HomeNavbar = () => {
  const classes = useStyles();
  const loading = useSelector(state => state.request.loading);
  const theme = useTheme();
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <List>
      <ListItem>
        <Fingerprint />
        <Button component={Link} to="/" color="inherit" onClick={handleDrawerToggle}>{t('navbar.login')}</Button>
      </ListItem>
    </List>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        {loading ? <ProgressBar /> : null}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Hidden smDown>
            <Grid container justify="flex-end" alignItems="flex-end" spacing={3}>
              <Grid item>
                <Button component={Link} to="/" color="inherit">
                  <Fingerprint className={classes.icons} />
                  {t('navbar.login')}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
};

export default HomeNavbar;
